//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Override primary color variants
$primary:       									#01416f; // Bootstrap variable
$primary-hover:    									#187DE4; // Custom variable
$primary-light:    									#E1F0FF; // Custom variable
$primary-inverse:  									#FFFFFF; // Custom variable

// Success colors
$success:       									#5ecf83 !default; // Bootstrap variable
$success-hover:    									#0f78ad !default; // Custom variable
$success-light:    									#9d9fcc3b !default; // Custom variable
$success-inverse:  									#ffffff !default; // Custom variable
